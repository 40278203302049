<template>
  <NuxtLink
    class="property-card-container"
    :to="`/property/${data.slug}`"
  >
    <div
      class="property-header"
      :style="{
        backgroundImage: `url(${data.thumbnail})`,
      }"
    >
      <div
        v-if="!isHideExclusive"
        class="property-other-badge"
      >
        <CheckCircleIcon
          width="12"
          height="12"
        />
        <Text
          type="p3"
          weight="medium"
        >
          Ekslusif
        </Text>
      </div>
      <button
        class="property-like-btn"
        :disabled="favoriteState.loading"
        @click.prevent="handleLikeProperty"
      >
        <component
          :is="loveIcon"
          width="18"
          height="18"
          fill="#333"
        />
      </button>
    </div>
    <div class="property-content">
      <div class="property-content-header">
        <Text
          type="p3"
          weight="medium"
          class="property-type-badge"
        >
          {{ data.category?.name }}
        </Text>
        <Text
          type="p1"
          weight="bold"
          class="property-price"
        >
          {{ formatNumber(data.price, true) }}
        </Text>
        <Text
          type="p2"
          weight="medium"
        >
          {{ data.name }}
        </Text>
      </div>

      <div
        v-for="(detail, idx) in details"
        :key="idx"
        class="property-content-detail"
      >
        <div>
          <component
            :is="detail.icon"
            width="16"
            height="16"
            class="detail-icon"
          />
        </div>
        <Text type="p2">
          {{ detail.text }}
          <template v-if="detail.isShowSizeUnit"> M<sup>2</sup> </template>
        </Text>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import BuildingIcon from '~/assets/icons/building.svg'
  import CheckCircleIcon from '~/assets/icons/check-circle.svg'
  import LandIcon from '~/assets/icons/land.svg'
  import LocationIcon from '~/assets/icons/location.svg'
  import LoveIcon from '~/assets/icons/love.svg'
  import LoveSolidIcon from '~/assets/icons/UserMenu/love-solid.svg'

  import { useFavoriteStore } from '~/store'
  import { formatAddress } from '~/utils'
  import { IProperty } from '~/interfaces'

  interface IPropertyCardProps {
    data: IProperty
    isHideExclusive?: boolean
  }

  interface IDetail {
    icon: string
    text: string
    isShowSizeUnit?: boolean
  }

  const props = defineProps<IPropertyCardProps>()

  const favoriteStore = useFavoriteStore()

  const { state: favoriteState } = storeToRefs(favoriteStore)

  const details: ComputedRef<IDetail[]> = computed(() => {
    const address = formatAddress({
      districtName: props.data.district?.name,
      cityName: props.data.city?.name,
      provinceName: props.data.province?.name,
    })

    return [
      {
        icon: LocationIcon,
        text: address,
      },
      {
        icon: LandIcon,
        text: formatNumber(props.data.surface_area),
        isShowSizeUnit: true,
      },
      {
        icon: BuildingIcon,
        text: formatNumber(props.data.building_area),
        isShowSizeUnit: true,
      },
    ]
  })

  const loveIcon = computed(() => {
    if (favoriteStore.isPropertyFaved(props.data.id)) {
      return LoveSolidIcon
    }

    return LoveIcon
  })

  const handleLikeProperty = async () => {
    await favoriteStore.togglePropertyFavorite({
      propertyId: props.data.id,
    })
  }
</script>

<style scoped lang="scss">
  .property-card-container {
    border: 1px solid $base500;
    background: $base100;
    min-width: 260px;

    .property-header {
      position: relative;
      background-size: cover;
      background-position: center;
      height: 160px;
    }

    .property-other-badge {
      display: flex;
      align-items: center;
      gap: 6px;
      background: $base300;
      padding: 4px 6px;
      position: absolute;
      top: 12px;
      left: 15px;
    }

    .property-like-btn {
      padding: 8px;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      background: $base100;
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;

      &:hover {
        opacity: 0.75;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    .property-content {
      padding: 16px;
    }

    .property-type-badge {
      background: $base300;
      padding: 4px 6px;
      width: fit-content;
    }

    .property-price {
      color: $orange500;
      margin: 8px 0 4px;
    }

    .property-content-header {
      margin-bottom: 20px;
    }

    .property-content-detail {
      display: flex;
      gap: 12px;
      align-items: center;

      .detail-icon {
        margin-top: 1px;
        vertical-align: middle;
      }

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
</style>
